import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/FirldTrips/Disaster/1.jpg';
import p2 from 'assests/Photos/FirldTrips/Disaster/2.jpg';
import p3 from 'assests/Photos/FirldTrips/Disaster/3.jpg';
import p4 from 'assests/Photos/FirldTrips/Disaster/4.jpg';
import p5 from 'assests/Photos/FirldTrips/Disaster/5.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SideBar from './SideBar';

const DisasterManagment = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source:p5,
      rows: 1,
      cols: 1,
    },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      DISASTER MANAGEMENT WORKSHSHOP
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Date: 13/10/2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Venue: Visveshwariah Industrial and Technological Museum<br/><br/>
Students of Classes 8, 9 and 10 of National Public School Yeshwanthpur attended a Disaster
Awareness Training Programme on the occasion of International Day for Disaster Risk
Reduction on 13 October 2022 at Visveshwariah Industrial and Technological Museum.<br/><br/>
The Museum in collaboration with IEEE Outreach India had organized the workshop to train
school and college students about disaster management techniques and create awareness
about it..<br/><br/>
The workshop was conducted by Mr Sunand Sampath, who is an expert in Disaster
Management.<br/><br/>
The programme began with the introduction to Disaster Management and the types of
disasters commonly dealt by people. The training mainly emphasized on hazards and risk
factors and the mitigation measures to be taken after the disaster strikes and how to bring life
back to normalcy.<br/><br/>
The students earnestly participated in a simulation on earthquake hit zone to understand the
devastation and disruption this catastrophe can bring to our lives.
The workshop was interesting and highly informative. It was thoroughly enjoyed and
appreciated by all the students.<br/><br/>
        </Typography>
      </Box>
      

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBar />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default DisasterManagment;